import script from "./Footer.vue?vue&type=script&setup=true&lang=js"
export * from "./Footer.vue?vue&type=script&setup=true&lang=js"

import "./Footer.vue?vue&type=style&index=0&id=294d3411&lang=scss"

const __exports__ = script;

export default __exports__
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QFooter,QToolbar,QToolbarTitle,QDialog,QCard,QCardSection});
